import React, { useState } from "react";
import 'typeface-roboto';
import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import './App.css';

const drawerWidth = 280;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    background: '#F5F5F5',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    marginLeft: drawerWidth,
    backgroundImage: 'url("KWLStripBackground.jpg")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    // [theme.breakpoints.up('sm')]: {
    //   width: `calc(100% - ${drawerWidth}px)`,
    // },
  },
  title: {
    flexGrow: 1,
    // fontSize: '120%',
    marginTop: theme.spacing(1),
  },
  menuButton: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%',
  },
  coreSpacing: {
    padding: theme.spacing(0, 2),
  },
  // card: {
  //   maxWidth: 345,
  // },
  card: {
    // padding: 0,
    width: '100%',
  },
  cardContent: {
    // paddingTop:0,
    // paddingBottom: 0,
  },
  cardContentNoBottom: {
    paddingBottom: 0,
  },
  cardAction: {
    justifyContent: 'flex-end',
    padding: theme.spacing(2),
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 16,
  },
  listItemText: {
    textAlign: 'right',
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  }, heading: {
    fontSize: theme.typography.pxToRem(18),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  code: {
    color: 'rgba(0, 0, 0, 0.87)',
    display: 'inline-block',
    padding: '2px 6px',
    borderRadius: '2px',
    backgroundColor: 'rgba(255,229,100,0.1)',
  },
  chapInline: {
    display: 'inline-block',
  },
  chipInline: {
    margin: theme.spacing(2, 0),
  },
  chipInlineFlex: {
    // margin: theme.spacing(2,1),
    flexGrow: 1,
  },
  gradeInline: {
    display: 'inline-block',
    paddingRight: '10px',
  },
  suggestionlist: {
    maxHeight: 200,
    overflowY: 'auto',
  },
  topSpace: {
    margin: '0px 0px 25px 0px',
    backgroundColor: 'transparent',
  },
  image: {
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('xs')]: {
      width: '100% !important', // Overrides inline-style
      height: 100,
    },
    '&:hover, &$focusVisible': {
      zIndex: 1,
      '& $imageBackdrop': {
        opacity: 0.4,
      },
      '& $imageMarked': {
        opacity: 0,
      },
      '& $imageTitle': {
        border: '4px solid currentColor',
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'contain',
    backgroundPosition: 'center 40%',
    backgroundRepeat: 'no-repeat',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.6,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    position: 'relative',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
}));

const theme = createMuiTheme({
  palette: {
    primary: { main: '#339933' },
    secondary: { main: '#424242' },
  },
  overrides: {
    rcPaginationItemActive: { backgroundColor: '#339933' },
  },
  typography: {
    // In Chinese and Japanese the characters are usually larger,
    // so a smaller fontsize may be appropriate.
    fontSize: 15,
  },
});

const images = [
  {
    url: 'cet.png',
    title: 'Tasmania',
    width: '50%',
    link: '/hobart',
    backgroundColor: '#DDDDDD'
  },
  {
    url: 'cew.jpg',
    title: 'Wollongong',
    width: '50%',
    link: '/wollongong',
    backgroundColor: '#F99D16'
  },
];


function App() {
  const [classes] = useState(useStyles());
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" component="h1" noWrap className={classes.title}>
              KWL Planning Hub
            </Typography>
          </Toolbar>
        </AppBar>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Container maxWidth="md">
            <Box my={4}>
              <Typography variant="h5" component="h2" gutterBottom>Select a diocese to start</Typography>
              {images.map((image) => (
                <ButtonBase
                  focusRipple
                  key={image.title}
                  className={classes.image}
                  focusVisibleClassName={classes.focusVisible}
                  style={{
                    width: image.width,
                    backgroundColor: image.backgroundColor,
                  }}
                  href={image.link}
                >
                  <span
                    className={classes.imageSrc}
                    style={{
                      backgroundImage: `url(${image.url})`,
                    }}
                  />
                  <span className={classes.imageBackdrop} />
                  <span className={classes.imageButton}>
                    <Typography
                      component="span"
                      variant="h6"
                      color="inherit"
                      className={classes.imageTitle}
                    >
                      {image.title}
                      <span className={classes.imageMarked} />
                    </Typography>
                  </span>
                </ButtonBase>
              ))}
            </Box>
          </Container>
        </main>
      </div>
    </ThemeProvider>
  );
}

export default App;
